// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.module.css */
.Login_root__laG5p {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #FDFDFD;
    width: 100%;
    gap: 0;
    /* padding: 0 20px; */
  }
  
  .Login_heading__Qm2SI {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    position: relative;
    top: 25px;
  }
  
  .Login_subheading__F5VA0 {
    font-size: 34px;
    
    color: #555;
    font-weight: 400;
  }
  
  .Login_versionText__aqSys {
    font-size: 16px;
    color: #999;
    /* margin-top: 1rem; */
    font-weight: 400;
  }
  
  .Login_googleButton__uQqG9 {
    width: 100% !important;
    max-width: 500px !important;
   
    font-size: 16px !important;
  }
  
  .Login_signInContainer__\\+kicP {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  @media (max-width: 600px) {
    .Login_heading__Qm2SI {
      font-size: 2.5rem;
    }
  
    .Login_subheading__F5VA0 {
      font-size: 1.3rem;
    }
  
    .Login_versionText__aqSys {
      font-size: 0.9rem;
    }
  
    .Login_googleButton__uQqG9 {
      width: 90%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoginPage/Login.module.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,MAAM;IACN,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,eAAe;;IAEf,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,sBAAsB;IACtB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,2BAA2B;;IAE3B,0BAA0B;EAC5B;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,iBAAiB;IACnB;;IAEA;MACE,UAAU;IACZ;EACF","sourcesContent":["/* Login.module.css */\n.root {\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    background-color: #FDFDFD;\n    width: 100%;\n    gap: 0;\n    /* padding: 0 20px; */\n  }\n  \n  .heading {\n    font-size: 3rem;\n    font-weight: bold;\n    color: #333;\n    position: relative;\n    top: 25px;\n  }\n  \n  .subheading {\n    font-size: 34px;\n    \n    color: #555;\n    font-weight: 400;\n  }\n  \n  .versionText {\n    font-size: 16px;\n    color: #999;\n    /* margin-top: 1rem; */\n    font-weight: 400;\n  }\n  \n  .googleButton {\n    width: 100% !important;\n    max-width: 500px !important;\n   \n    font-size: 16px !important;\n  }\n  \n  .signInContainer {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n  }\n  \n  @media (max-width: 600px) {\n    .heading {\n      font-size: 2.5rem;\n    }\n  \n    .subheading {\n      font-size: 1.3rem;\n    }\n  \n    .versionText {\n      font-size: 0.9rem;\n    }\n  \n    .googleButton {\n      width: 90%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Login_root__laG5p`,
	"heading": `Login_heading__Qm2SI`,
	"subheading": `Login_subheading__F5VA0`,
	"versionText": `Login_versionText__aqSys`,
	"googleButton": `Login_googleButton__uQqG9`,
	"signInContainer": `Login_signInContainer__+kicP`
};
export default ___CSS_LOADER_EXPORT___;
