import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css'; // Import the CSS module
import { borderRadius } from '@mui/system';

const Login = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  



  useEffect(() => {
    // Dynamically load Google API script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.onload = () => initializeGoogleSignIn(); // Call initialization after script is loaded
      document.body.appendChild(script);
    };

    // Initialize Google Sign-In after script loads
    const initializeGoogleSignIn = () => {
      /* global google */
      google.accounts.id.initialize({
        client_id: '459019583238-gc9ur1kt1jb0fmba3jo8dq47ikh2n1fb.apps.googleusercontent.com',
        callback: handleCallbackResponse,
      });

      google.accounts.id.renderButton(
        document.getElementById('googleSignInDiv'),
        { theme: 'outline', size: 'large', width: '100%', borderRadius: '9999px' } // Ensure button is responsive
      );

      const button = document.querySelector('#googleSignInDiv > div');
      if (button) {
        button.style.fontSize = '16px';
      }
    };

    loadGoogleScript(); // Call the function to load the script
  }, []);

  

  const handleCallbackResponse = (response) => {
    console.log("Encoded JWT ID token: " + response.credential);

    const userObject = JSON.parse(atob(response.credential.split('.')[1]));
    console.log("Decoded User Info:", userObject);

    setUserInfo(userObject);
    localStorage.setItem('user', JSON.stringify(userObject));

    // Send user data to your API
    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        googleId: userObject.sub,
        email: userObject.email,
        name: userObject.name,
        firstName: userObject.given_name,
        lastName: userObject.family_name,
        profilePicture: userObject.picture,
        locale: userObject.locale,
        emailVerified: userObject.email_verified,
      }),
    })
      .then(response => response.json())
      .then(data => {
        console.log('User saved:', data);
      })
      .catch(error => {
        console.error('Error saving user:', error);
      });

    navigate('/landing');
  };

  return (
    <div  className={styles.root}>
      <div style={{marginBottom:"240px"}}>
        <h1 className={styles.heading}>Rainfall.</h1>
        <h2 className={styles.subheading}>Insight Simulator</h2>
        <p className={styles.versionText}>Version 1.0</p>
        <div className={styles.signInContainer}>
          <div id="googleSignInDiv" className={styles.googleButton}></div>
        </div>
      </div>
    </div>
  );
};

export default Login;



