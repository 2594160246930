


  import React, { useState, useEffect ,useRef} from 'react';
  import { Button, TextField, Box, Snackbar, Alert, CircularProgress, Typography, Menu, MenuItem, TextareaAutosize } from '@mui/material';
  import styles from './CreateBusinessForm.module.css';
  // import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
  import axios from 'axios'; // Import axios for API calls
  import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
  import LocationOnIcon from '@mui/icons-material/LocationOn';
  import BusinessDisplay from './BusinessDisplay';
  // import { Navigate } from 'react-router-dom';
  import { useNavigate } from 'react-router-dom';
  
  const CreateBusinessForm = ({ handleCancelForm, onSubmit, businessNames, resetForm }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [businessName, setBusinessName] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [businessData, setBusinessData] = useState(null); 
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showForm, setShowForm] = useState(true); // Control the visibility of the form
    const [errors, setErrors] = useState({}); // Store validation errors
    const [isLoading, setIsLoading] = useState(false); // Loader state for submit button
    // const [showEdit, setShowEdit] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const navigate = useNavigate();
    // const [fetchedBusinessIds, setFetchedBusinessIds] = useState([]);
  
  
    const [showBusinessDisplay, setShowBusinessDisplay] = useState(false);
    useEffect(() => {
      if (resetForm) {
        resetFormFields();
        
      }
    }, [resetForm]);
  
    const resetFormFields = () => {
      
      setBusinessName('');
      setLocation('');
      setDescription('');
      setIsEditing(false);
      setBusinessData([]); 
      fetchedBusinessIds.current.clear();
       
      setShowBusinessDisplay(false)
      setShowForm(true) 
      navigate('/landing');
    };
  
  
  
  
  

  const fetchedBusinessIds = useRef(new Set()); 
  console.log("fetchedBusinessIds" ,fetchedBusinessIds);

  useEffect(() => {
    const fetchBusinessData = async () => {
      try {
        // console.log("Fetching business data for ID:", businessNames);
        
        const response = await axios.get(`api/getBusinessByID/${businessNames}`);
        setBusinessData(response.data);
        setBusinessName(response.data.name);
        setLocation(response.data.location);
        setDescription(response.data.summary);

        // Store the ID of the fetched business to prevent duplicate API calls
        fetchedBusinessIds.current.add(businessNames);
        setShowForm(false);
        setShowBusinessDisplay(true)

        const dummyResponse = `Action:Open ${response.data.name}`;
        const oracleResponse1 = `${response.data.name} does not have a campaign yet. Would you like to generate a new campaign for this business?`;

        onSubmit(dummyResponse, oracleResponse1);
      } catch (error) {
        console.error('Error fetching business data:', error);
      }
    };

    // Fetch data only if businessNames is defined and hasn't been fetched before
    if (businessNames && !fetchedBusinessIds.current.has(businessNames)) {
      fetchBusinessData();
    }
  }, [businessNames, onSubmit]); // dependencies updated


  
  const validateForm = () => {
    let formErrors = {};
    if (!businessName) formErrors.businessName = 'Business name is required';
    if (!location) formErrors.location = 'Location is required';
    if (!description) formErrors.description = 'Description is required';
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  
  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
    if (errors.businessName) setErrors((prev) => ({ ...prev, businessName: '' }));
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
    if (errors.location) setErrors((prev) => ({ ...prev, location: '' }));
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    if (errors.description) setErrors((prev) => ({ ...prev, description: '' }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-menu' : undefined;

  const handleFormSubmit = async () => {
    if (!validateForm()) {
      return; // If validation fails, don't proceed
    }

    // Show loader while API request is in progress
    setIsLoading(true);



    try {
      const postData = {
        name: businessName,
        summary: description,
        location: location,
      };

      let response;

      if (isEditing && businessData) {
        // PUT request to update business data
        response = await axios.put(`api/updateBusinessByID/${businessData.id}`, postData);
        // Update the local business data to reflect the edit
        setBusinessData((prevData) => ({
          ...prevData,
          name: businessName,
          summary: description,
          location: location,
        })); 

        const dummyResponse = `Action:Update `;
        const oracleRespnse1 = `I gave your business a name for now, based on your description. You may change this anytime.`
        const oracleRespnse2 = `${businessName} Co. does not have a campaign yet. Would you like to generate a new campaign for this business?`
    
        onSubmit(dummyResponse, oracleRespnse1, oracleRespnse2);
      }
      
      else {
        // POST request to submit new business data
        response = await axios.post('api/addBusiness', postData);
        
        const getResponse = await axios.get('api/getAllBusiness/');

        if (getResponse.status === 200) {
          const latestBusiness = getResponse.data.reduce((prev, current) => {
            return (prev.id > current.id) ? prev : current;
          }, getResponse.data[0]);

          // Update state to display the latest business
          setBusinessData(latestBusiness);
          setShowBusinessDisplay(true);
          console.log("latestBusiness", latestBusiness);
        }
        // resetFormFields(); 
        const dummyResponse = `Action:Done `;
        const oracleRespnse1 = `I gave your business a name for now, based on your description. You may change this anytime.`
        const oracleRespnse2 = `${businessName} Co. does not have a campaign yet. Would you like to generate a new campaign for this business?`
    
        onSubmit(dummyResponse, oracleRespnse1, oracleRespnse2);
    
      }

      setShowForm(false); // Hide the form after submission
      setShowSnackbar(true); // Show success snackbar

      // // Clear form inputs and localStorage after submission
      // setBusinessName('');
      // setLocation('');
      // setDescription('');

    } catch (error) {
      console.error('Error submitting the form:', error);
    } finally {
      setIsLoading(false);
    }
  };


  // Handle business selection for editing
  const handleEditClick = () => {
    setShowForm(true);
    setIsEditing(true); // Enable edit mode
    setAnchorEl(null);
    // Populate form fields with the selected business data
    if (businessData) {
      setBusinessName(businessData.name);
      setLocation(businessData.location);
      setDescription(businessData.summary);
    }

  };

  return (
    <div className={styles.rightside}>
      {showForm ? (
        // Show form for adding/editing business
        <Box style={{ minHeight: '600px' }} className={styles.formContainer}>
          {/* <Button variant="outlined" color="secondary" onClick={handleCancelForm}>
            <ClearRoundedIcon />
          </Button> */}
          <Button
            sx={{ float: 'right', marginTop: '0px' ,backgroundColor:"#00BFA5" }}
            variant="contained"
            color="primary"
            onClick={handleFormSubmit}
            disabled={isLoading} // Disable button while loading
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isEditing ? 'Update' : 'Done'}
          </Button>
          <TextField
            label="Business name"
            fullWidth
            margin="normal"
            value={businessName}
            onChange={handleBusinessNameChange}
            error={!!errors.businessName}
            helperText={errors.businessName}
          />
          <TextField
            label="Location"
            fullWidth
            margin="normal"
            value={location}
            onChange={handleLocationChange}
            error={!!errors.location}
            helperText={errors.location}
          />
        
        {errors.description && (
  <Typography variant="caption" color="error">
    {errors.description}
  </Typography>
)}
         <TextareaAutosize
     
  aria-label="Description"
  placeholder="Enter your description"
  minRows={3}  
  maxRows={19} 
  style={{
    width: '100%',      
    maxHeight: '300px', 
    overflowY: 'auto',   
    padding: '10px',    
    boxSizing: 'border-box',  
    fontFamily:"Roboto",
    fontSize:"16px",
    borderColor: errors.description ? 'red' : undefined, // Change border color on error
    borderWidth: errors.description ? '1px' : '1px',  

  }}
  value={description}
  onChange={handleDescriptionChange}
  error={!!errors.description}
  helperText={errors.description}
/>


        </Box>
      ) : (
        // Show fetched or newly added business data
        showBusinessDisplay && businessData && (
          <div className={styles.summaryContainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button color="secondary" onClick={handleCancelForm}>
                <ClearRoundedIcon />
              </Button>
              <Button onClick={toggleMenu}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="black" fillOpacity="0.54" />
                </svg>
              </Button>
            </div>

            <Menu id={id} anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem  onClick={handleEditClick}>Edit</MenuItem>
            </Menu>

            <h2 style={{ color: "black" }}>{businessData.name}</h2> {/* Display latest business name */}
            <div className={styles.locationIcon}>
              <LocationOnIcon />
              <p>{businessData.location}</p>
            </div>
            <p style={{maxHeight:"250px" , overflow:"auto"}} className={styles.businessDescription}>{businessData.summary}</p>
            <div className={styles.buttonNewCampaign}>+ New Campaign</div>
            <table className={styles.tableContainer}>
              <thead>
                <tr>
                  <th>Campaign Title</th>
                  <th>Last Modified</th>
                  <th>Data %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={styles.noCampaigns} colSpan="3">No campaigns</td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      )}

      {/* Snackbar for showing success message */}
      <Snackbar open={showSnackbar} autoHideDuration={3000} onClose={() => setShowSnackbar(false)}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {isEditing ? 'Business updated successfully!' : 'Business submitted successfully!'}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CreateBusinessForm;