import React, { useEffect, useState, useRef } from 'react';
import { AppBar,TextField, IconButton, Typography, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SendIcon from '@mui/icons-material/Send';
import CreateBusinessForm from '../CreateBusiness/CreateBusinessForm'; // Import form component
import styles from './LandingPage.module.css';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import { useNavigate } from 'react-router-dom';
import { display } from '@mui/system';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {formatBusinessProfile} from './formatBusinessProfile'
import icon from "../asset/Icon.svg"
import menu from "../asset/menu.svg"


const LandingPage = ({showForm , setShowForm}) => {
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [inputValue, setInputValue] = useState("Let's generate a new business")
  const [inputValue, setInputValue] = useState('');
  const [inputPlaceholder, setInputPlaceholder] = useState('Message the Oracle');
  const [showTechIntegration, setShowTechIntegration] = useState(false);
  const [messages, setMessages] = useState([
    { text: 'Welcome! You currently do not have a business yet. What would you like to do next?', sender: 'system' },
  ]);
  const [resetForm, setResetForm] = useState(false);
  // const [showForm, setShowForm] = useState(false); // State to toggle the form visibility
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const chatContainerRef = useRef(null); 
  const [isFirstClick, setIsFirstClick] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  // const { businessName } = location.state || {};
  const [isEditing, setIsEditing] = useState(false);
  const [isGeneratingBusiness, setIsGeneratingBusiness] = useState(false);
  const { businessName } = location.state || {};
  
  // Step 1: Initialize component state with the businessName from location.state
  const [currentBusinessName, setCurrentBusinessName] = useState(businessName);
  
useEffect(() => {
  setInputValue(''); // Reset input on page load
}, []);


useEffect(() => {
  if (businessName) {
    setShowForm(true);  
  }
  else (
    setResetForm(false)
  )
}, [])


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const toggleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10; 
      setShowTechIntegration(isAtBottom);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  const resetBusinessName = () => {
    setCurrentBusinessName('');
  }


  const handleSendClick = async () => {
    if (inputValue.trim()) {
     
          setShowForm(true);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: inputValue, sender: 'user' },
          ]);
    
          if (inputValue.toLowerCase() === "let's generate a new business") {
            // Handle the initial predefined response from the system
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: 'Let’s get started. In the description field, describe the business profile you’d like to create.', sender: 'system' },
            ]);
            setIsGeneratingBusiness(true);


          } 
          
          else  {
            
            // Show typing indicator before making the API call
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: 'Typing...', sender: 'system', typing: true }, // Mark this message as typing
            ]);
            let data = {
              question: inputValue,
              config: {},
              kwargs: {},
            };
    
            let config = {
              method: 'post',
              url: 'api/generateBusinessChat',
              headers: {
                'Content-Type': 'application/json',
              },
              data: JSON.stringify(data),
            };
    
            try {
              // Make the API call
              const response = await axios(config);
    
              console.log("API Response:", response);

              // Adjust based on the actual structure of the response
              // Replace 'response.data?.message' with the correct path based on your API response structure
              const oracleResponse = response.data?.message || response.data || 'No response from system';
              setMessages((prevMessages) => {
                // Remove the last "Typing..." message
                const formattedResponse = formatBusinessProfile(oracleResponse);
                const updatedMessages = prevMessages.slice(0, -1);
                return [
                  ...updatedMessages,
                  { text: formattedResponse, sender: 'system' },
                ];
              });
      
              // console.log("oracleResponse", formattedResponse);
              
            } catch (error) {
              // Handle errors and show an error message from system
              setMessages((prevMessages) => [
                ...prevMessages,
                { text: 'Failed to get a response from system. Please try again.', sender: 'system' },
              ]);
            }
          }
    
          // Clear the input field
          setInputValue('');
        }
      };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-menu' : undefined;

  const handleCreateBusiness = () => {
     
   
  
    let businessMessage = '';
    resetBusinessName()
    setResetForm(true);     
    setShowForm(true);   

    // setIsEditing(false);   
    setMessages([
      { text: 'Welcome! You currently do not have a business yet. What would you like to do next?', sender: 'system' },
    ])
    setResetForm(true);  
    // if (isFirstClick) {
    //   businessMessage = "Let's generate a new business";
    //   setIsFirstClick(false);
    // }
    
    // setInputValue(businessMessage);

    
  
    // Simulate sending the message automatically only on the first click
    if (businessMessage !== '') {
      setMessages([
        ...messages,
        { text: businessMessage, sender: 'user' },
        { text: "Let’s get started. In the description field, describe the business profile you’d like to create.", sender: 'system' }, // Oracle's response
      ]);
    }
    setInputValue("");
    // setShowForm(true); 
    setAnchorEl(null);
    // setInputValue('');
  
  setIsFirstClick(true); 
   
  };


  const handleFormSubmit = (dummyResponse, oracleResponse1,oracleResponse2) => {
    // First, update the messages with the dummy response from the user


    setMessages(prevMessages => [
      ...prevMessages, 
      { text: dummyResponse, sender: 'user' }
    ]);
  
    
    // Then, update the messages with the oracle response from the system after the user message is added
    setMessages(prevMessages => [
      ...prevMessages, 
      { text: oracleResponse1, sender: 'system' }
    ]);

    // setMessages(prevMessages => [
    //   ...prevMessages, 
    //   { text: oracleResponse2, sender: 'system' }
    // ]);

    if (oracleResponse2.trim() !== '') { // Check if the response is not blank
      setMessages(prevMessages => [
        ...prevMessages, 
        { text: oracleResponse2, sender: 'system' }
      ]);
    }
  };
  
  const handleCancelForm = () => {
    setShowForm(false);
  };
  const handleInputBlur = () => {
    setIsFocused(false);
    setInputPlaceholder('Message the Oracle');
  };
  const handleInputFocus = () => {
    setIsFocused(true);
   
    let businessMessage = '';
    if (isFirstClick) {
      businessMessage = "";
      setIsFirstClick(false); 
    }
    setInputValue(businessMessage);
  };
  const handleAllBusiness = () => {
    navigate('/BusinessList'); 
  };


  const handleLogout = () => {
    // Clear all localStorage items
    localStorage.clear();

    // Redirect to the homepage
    navigate('/'); // Change this to the correct route for your homepage
  };
  return (
    <div className={styles.container}>
      {/* Left side */}
      <Box className={`${styles.leftSide} ${showForm ? styles.halfWidth : ''}`}>
        {/* Header */}
        <Box position="static" color="transparent" elevation={0} className={styles.header}>
          <IconButton className={styles.title} onClick={toggleMenu}>
            {/* <MenuIcon style={{color:"#00fff7ed"}}/> */}
            <img src={menu} ></img>
            {/* icon */}
           
          </IconButton>

          <Typography edge="end" className={styles.menuButton} aria-label="menu">
          <img src={icon} ></img>
          </Typography>
        </Box>

        <Menu
          id={id}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleCreateBusiness}>Create New Business</MenuItem>
          <MenuItem onClick={handleAllBusiness}>All Businesses</MenuItem>
          <MenuItem onClick={handleClose}>All Campaigns</MenuItem>
          <MenuItem onClick={handleClose}>All Customers</MenuItem>
          <MenuItem onClick={handleLogout}>Log out</MenuItem>
        </Menu>
        {/* Chat messages */}
        <Box className={styles.chatContainer} ref={chatContainerRef} onScroll={handleScroll}>
          {messages.length > 0 && messages.map((message, index) => {
            if (message.sender === 'user') {
              return (
                <Box key={index} className={`${styles.chatMessage} ${styles.userMessage}`}>
                  <Typography variant="body2" className={styles.userText}>
                    {message.text}   <span style={{ position: "relative", top: "10px", left: "7px" }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" rx="12" fill="#1DE9B6" />
                      <path d="M8.23535 7.67969H9.23975L11.8018 14.0552L14.3584 7.67969H15.3682L12.1885 15.5H11.4043L8.23535 7.67969ZM7.90771 7.67969H8.79395L8.93896 12.4492V15.5H7.90771V7.67969ZM14.8042 7.67969H15.6904V15.5H14.6592V12.4492L14.8042 7.67969Z" fill="#444444" />
                    </svg>
                    </span>
                  </Typography>
                </Box>
              );
            } else if (message.sender === 'system') {
              return (
                <Box style={{display:"flex"}} key={index} className={`${styles.chatMessage} ${styles.systemMessage}`}>
                  <span style={{ position: "relative", top: "0px", right: "7px" }} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="24" height="24" rx="12" fill="white" />
                      <path d="M14.9287 11.3428V11.8369C14.9287 12.4242 14.8553 12.9505 14.7085 13.416C14.5617 13.8815 14.3504 14.2772 14.0747 14.603C13.799 14.9289 13.4678 15.1777 13.0811 15.3496C12.6979 15.5215 12.2682 15.6074 11.792 15.6074C11.3301 15.6074 10.9058 15.5215 10.519 15.3496C10.1359 15.1777 9.8029 14.9289 9.52002 14.603C9.24072 14.2772 9.02409 13.8815 8.87012 13.416C8.71615 12.9505 8.63916 12.4242 8.63916 11.8369V11.3428C8.63916 10.7555 8.71436 10.231 8.86475 9.76904C9.01872 9.30355 9.23535 8.90788 9.51465 8.58203C9.79395 8.2526 10.1252 8.00195 10.5083 7.83008C10.895 7.6582 11.3193 7.57227 11.7812 7.57227C12.2575 7.57227 12.6872 7.6582 13.0703 7.83008C13.457 8.00195 13.7882 8.2526 14.064 8.58203C14.3433 8.90788 14.5563 9.30355 14.7031 9.76904C14.8535 10.231 14.9287 10.7555 14.9287 11.3428ZM13.9028 11.8369V11.332C13.9028 10.8665 13.8545 10.4548 13.7578 10.0967C13.6647 9.73861 13.5269 9.43783 13.3442 9.19434C13.1616 8.95085 12.9378 8.76644 12.6729 8.64111C12.4115 8.51579 12.1143 8.45312 11.7812 8.45312C11.459 8.45312 11.1672 8.51579 10.9058 8.64111C10.6479 8.76644 10.4259 8.95085 10.2397 9.19434C10.0571 9.43783 9.91569 9.73861 9.81543 10.0967C9.71517 10.4548 9.66504 10.8665 9.66504 11.332V11.8369C9.66504 12.306 9.71517 12.7214 9.81543 13.083C9.91569 13.4411 10.0589 13.7437 10.2451 13.9907C10.4349 14.2342 10.6587 14.4186 10.9165 14.5439C11.1779 14.6693 11.4697 14.7319 11.792 14.7319C12.1286 14.7319 12.4276 14.6693 12.689 14.5439C12.9504 14.4186 13.1706 14.2342 13.3496 13.9907C13.5322 13.7437 13.6701 13.4411 13.7632 13.083C13.8563 12.7214 13.9028 12.306 13.9028 11.8369Z" fill="#444444" />
                    </svg></span>
                  <Typography  variant="body2" className={styles.systemText}>
                       {message.text} 

                  </Typography>
                </Box>
              );
            }
            return null;
          })}
        </Box>

        {/* Bottom input */}
        <Box className={styles.bottomInput}>
         
           <TextField
            className={styles.inputField}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={handleInputFocus}
        placeholder={inputPlaceholder}
        onBlur={handleInputBlur}
        multiline
        maxRows={144} // Adjust to limit the height
        variant="outlined"
        onKeyDown={(e) => e.key === 'Enter' && handleSendClick()}
        style={{ opacity: isFocused ? 1 : 0.8 }} 
           
        sx={{
          flex: 1, // Make it take up available space
          marginRight: '8px',
         
        }}
        InputProps={{
          style: {
            paddingTop: '15px',
            paddingLeft:"10px",
            paddingBottom:"15px",
            paddingRight:"40px"
          },
          
        }}
      />
         <IconButton style={{position:"relative" , right:"50px" ,opacity: isFocused ? 1 : 0.2 , pointerEvents: inputValue.trim() ? "auto" : "none"}} className={styles.sendButton} onClick={handleSendClick}  disabled={!inputValue.trim()}>
            <SendIcon  style={{ color: inputValue.trim() ? '#00BFA5' : '#ccc' }} />
          </IconButton>
         
        </Box>

      </Box>

      {/* Right side - Form */}
      {showForm && (
        <Box className={styles.rightSide}>
          <CreateBusinessForm   
            // Clear business-related state
            resetForm={resetForm}
            isEditing={false}
          handleCancelForm={handleCancelForm}  onSubmit={handleFormSubmit} businessNames={currentBusinessName}/>
        </Box>
      )}
    </div>
  );
};

export default LandingPage;















