// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId = "459019583238-5a3ge8bg3atu1am7r7b75p90dofhjm4p.apps.googleusercontent.com"; 

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
