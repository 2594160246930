// BusinessDisplay.js
import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import styles from './CreateBusinessForm.module.css';

const BusinessDisplay = ({ businessData, handleCancelForm, handleEditClick, toggleMenu, open, anchorEl, handleClose }) => {
  return (
    <div className={styles.summaryContainer}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button color="secondary" onClick={handleCancelForm}>
          <ClearRoundedIcon />
        </Button>
        <Button onClick={toggleMenu}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="black" fillOpacity="0.54" />
          </svg>
        </Button>
      </div>

      <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
      </Menu>

      <h2 style={{ color: "black" }}>{businessData.name}</h2>
      <div className={styles.locationIcon}>
        <LocationOnIcon />
        <p>{businessData.location}</p>
      </div>
      <p style={{ maxHeight: "250px", overflow: "auto" }} className={styles.businessDescription}>
        {businessData.summary}
      </p>
      <div className={styles.buttonNewCampaign}>+ New Campaign</div>
      <table className={styles.tableContainer}>
        <thead>
          <tr>
            <th>Campaign Title</th>
            <th>Last Modified</th>
            <th>Data %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={styles.noCampaigns} colSpan="3">No campaigns</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BusinessDisplay;
