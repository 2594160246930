//  export const formatBusinessProfile = (response) => {
//     const sections = response.split("**").slice(1);
  
//     const businessProfile = {
//       businessOverview: sections[0] ? sections[0].trim() : "",
//       uniqueSellingPoints: sections[1] ? sections[1].trim() : "",
//       targetAudience: sections[2] ? sections[2].trim() : "",
//       businessGoals: sections[3] ? sections[3].trim() : "",
//       communityEngagement: sections[4] ? sections[4].trim() : "",
//       contactInformation: sections[5] ? sections[5].trim() : "",
//     };
  
//     return (
//       <div >
        
//         {/* <h3>Business Overview:</h3> */}
//         <p style={{marginBottom:"15px"}}>{businessProfile.businessOverview}:</p>
  
//         {/* <h3>Unique Selling Points:</h3> */}
//         {/* <ul> */}
//           <p>{businessProfile.uniqueSellingPoints}:</p>
//         {/* </ul> */}
  
//         {/* <h3>Target Audience:</h3> */}
//         {/* <ul> */}
//           <p>{businessProfile.targetAudience}:</p>
//         {/* </ul> */}
  
//         {/* <h3 style={{ color: "blue", fontWeight: "bold" }}>Business Goals:</h3> */}
//         <p>{businessProfile.businessGoals}:</p>
  
//         {/* <h3>Community Engagement:</h3> */}
//         {/* <ul> */}
//           <p>{businessProfile.communityEngagement}:</p>
//         {/* </ul> */}
  
//         {/* <h3>Contact Information:</h3> */}
//         {/* <ul> */}
//           <p>{businessProfile.contactInformation}:</p>
//         {/* </ul> */}
//       </div>
//     );
//   };

// export const formatBusinessProfile = (response) => {
//   // Updated extraction function with multiline matching for each section
//   const extractSection = (label) => {
//       const regex = new RegExp(`${label}:\\s*([\\s\\S]*?)(?=\\n\\w+:|$)`, 'g'); // Match everything until the next label or end
//       const match = response.match(regex);
//       return match ? match[0].replace(`${label}:`, '').replace(/\*/g, '').trim() : "No information provided";
//   };

//   // Specifically extract only the business name without any additional text
//   const extractBusinessName = () => {
//       const regex = /Business Name:\s*(.*?)\n/;
//       const match = response.match(regex);
//       return match ? match[1].trim() : "No information provided";
//   };

//   // Extract each section of the business profile
//   const businessProfile = {
//       businessName: extractBusinessName(),
//       businessModel: extractSection("Business Model"),
//       keyFeatures: extractSection("Key Features of Business"),
//       targetMarket: extractSection("Target Market"),
//       technologyIntegration: extractSection("Technology Integration"),
//       sustainabilityInitiatives: extractSection("Sustainability Initiatives"),
//       expansionStrategy: extractSection("Expansion Strategy"),
//   };

//   // Render sections within a clean layout
//   return (
//     <div>
//       <h3>Business Name:</h3>
//       <p>{businessProfile.businessName}</p>
      
//       <h3>Business Model:</h3>
//       <p>{businessProfile.businessModel}</p>
      
//       <h3>Key Features of Business:</h3>
//       <p>{businessProfile.keyFeatures}</p>
      
//       <h3>Target Market:</h3>
//       <p>{businessProfile.targetMarket}</p>
      
//       <h3>Technology Integration:</h3>
//       <p>{businessProfile.technologyIntegration}</p>
      
//       <h3>Sustainability Initiatives:</h3>
//       <p>{businessProfile.sustainabilityInitiatives}</p>
      
//       <h3>Expansion Strategy:</h3>
//       <p>{businessProfile.expansionStrategy}</p>
//     </div>
//   );
// };


export const formatBusinessProfile = (response) => {
  // Function to extract sections with more flexible label and whitespace matching
  const extractSection = (label) => {
    // Match the label, allowing for symbols like '**' and extra spaces, then capture content until the next label or end
    const regex = new RegExp(`${label}\\s*:?\\s*([\\s\\S]*?)(?=\\n[A-Z][a-zA-Z ]+\\s*:?|$)`, 'g');
    const match = response.match(regex);
    return match ? match[0].replace(new RegExp(`${label}\\s*:?\\s*`), '').replace(/\*+/g, '').trim() : "No information provided";
  };

  // Specifically extract only the business name without additional symbols
  const extractBusinessName = () => {
    const regex = /Business Name:\s*(.*?)\n/;
    const match = response.match(regex);
    return match ? match[1].replace(/\*+/g, '').trim() : "No information provided";
  };

  // Extract each section of the business profile
  const businessProfile = {
    businessName: extractBusinessName(),
    businessModel: extractSection("Business Model"),
    keyFeatures: extractSection("Key Features of Business"),
    targetMarket: extractSection("Target Market"),
    technologyIntegration: extractSection("Technology Integration"),
    sustainabilityInitiatives: extractSection("Sustainability Initiatives"),
    expansionStrategy: extractSection("Expansion Strategy"),
  };

  // Render sections within a clean layout
  return (
    <div>
      <h3>Business Name:</h3>
      <p>{businessProfile.businessName}</p>
      
      <h3>Business Model:</h3>
      <p>{businessProfile.businessModel}</p>
      
      <h3>Key Features of Business:</h3>
      <p>{businessProfile.keyFeatures}</p>
      
      <h3>Target Market:</h3>
      <p>{businessProfile.targetMarket}</p>
      
      <h3>Technology Integration:</h3>
      <p>{businessProfile.technologyIntegration}</p>
      
      <h3>Sustainability Initiatives:</h3>
      <p>{businessProfile.sustainabilityInitiatives}</p>
      
      <h3>Expansion Strategy:</h3>
      <p>{businessProfile.expansionStrategy}</p>
    </div>
  );
};


