import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes ,Navigate } from 'react-router-dom';

import Login from './components/LoginPage/Login';
import LandingPage from './components/LandingPage/LandingPage';
import BusinessList from './components/BusinessList/BusinessList';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css'
import './index.css'
import PrivateRoute from './components/PrivateRoute';
const theme = createTheme();


function App() {

  const [showForm, setShowForm] = useState(false);
  return (
    <ThemeProvider theme={theme}>
    <Router>
    <Routes>
      <Route path="/landing" element={<PrivateRoute>  <LandingPage showForm={showForm} setShowForm={setShowForm}  /></PrivateRoute>} />
      <Route path="/BusinessList" element={<PrivateRoute>  <BusinessList showForm={showForm} setShowForm={setShowForm}  /></PrivateRoute>} />

{/* <Route path="/landing" element={  <LandingPage />} /> */}
{/* <Route path="/BusinessList" element={ <BusinessList />} /> */}
      <Route path="/" element={<Login />} />

      <Route path="*" element={<Navigate to="/" />} />

    </Routes>
  </Router>
  </ThemeProvider>
  
  );
}

export default App;
